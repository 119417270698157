const moment = require("moment")
const $body = $("body")

function getCookie(name) {
    var cookieValue = null
    if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";")
        for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i])
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                )
                break
            }
        }
    }
    return cookieValue
}

let csrftoken = getCookie("csrftoken")

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
}

$.ajaxSetup({
    beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken)
        }
    },
})

$(".clickable").on("click", function () {
    window.location.href = $(this).data("url")
})

function initialiseDatepickers() {
    $(".datepicker")
        .datepicker({
            format: "dd/mm/yyyy",
            autoclose: true,
            calendarWeeks: true,
            clearBtn: true,
            todayHighlight: true,
            weekStart: "1",
        })
        .attr("autocomplete", "off")
}

function initialiseDaterangePickers() {
    const defaultStart = moment().startOf("month")
    const defaultEnd = moment().endOf("month")

    $(".qr-daterangepicker").each(function () {
        const $this = $(this)
        function cb(start, end) {
            $this.val(
                start.format("DD/MM/YYYY") + " - " + end.format("DD/MM/YYYY")
            )
        }
        $this.daterangepicker(
            {
                startDate: defaultStart,
                endDate: defaultEnd,
                ranges: {
                    Today: [moment(), moment()],
                    Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                    ],
                    "Last 7 Days": [moment().subtract(6, "days"), moment()],
                    "Last 30 Days": [moment().subtract(29, "days"), moment()],
                    "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                    ],
                    "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                    ],
                    "Next 7 Days": [moment(), moment().add(6, "days")],
                    "Next 30 Days": [moment(), moment().add(29, "days")],
                },
                locale: {
                    format: "DD/MM/YYYY",
                    cancelLabel: "Clear",
                },
            },
            cb
        )
        cb(defaultStart, defaultEnd)
    })
}
$body.on("keydown", ".qr-daterangepicker", function (e) {
    e.preventDefault()
})
$body.on("cancel.daterangepicker", ".qr-daterangepicker", function () {
    $(this).val("")
    $(this).trigger("input")
})
$(document).ready(function () {
    initialiseDatepickers()
    initialiseDaterangePickers()
})

$body.on("click", ".form-clearable-field .form-clear-btn", function (e) {
    e.preventDefault()
    const $input = $(this)
        .closest(".form-clearable-field")
        .find("input[type=text]")
    if ($input.hasClass("qr-daterangepicker")) {
        $input.trigger("cancel.daterangepicker")
    } else {
        $input.val("")
    }
    $(this).hide()
})
$body.on("apply.daterangepicker", ".qr-daterangepicker", function () {
    const $btn = $(this)
        .closest(".form-clearable-field")
        .find(".form-clear-btn")
    if ($(this).val()) {
        $btn.show()
    } else {
        $btn.hide()
    }
})

// Add back auto-focus of select2 choice fields
$(document).on("select2:open", (e) => {
    const targetID = e.target.getAttribute("id")
    if (
        $(e.target).attr("multiple") === undefined ||
        !$(e.target).attr("multiple")
    ) {
        const select2Field = document.querySelector(
            `.select2-search__field[aria-controls="select2-${targetID}-results"]`
        )
        if (select2Field) {
            select2Field.focus()
        }
    }
})

export { initialiseDaterangePickers, initialiseDatepickers }
