function productWarning() {
    Swal.fire({
        icon: "warning",
        titleText: "Product not active",
        text: "Please contact your Quick Release Account Manager",
        confirmButtonColor: "#ffc107",
        customClass: {
            confirmButton: "btn btn-warning",
        },
    })
}

$(document).ready(function () {
    $(".product-warning").on("click", productWarning)
})
